<template>
  <div>
    <div class="comments-container p-px-4">
      <div v-if="comments.length" class="comments p-pb-2">
        <div
          v-for="comment in comments"
          :key="comment.id"
          class="comments-item p-d-flex p-ai-start"
        >
          <div class="comments-date p-d-flex p-ai-center">
            {{ dayjs(comment.created_at).format("YYYY-MM-DD") }}
          </div>
          <div class="p-ml-4">
            <div class="p-mb-3 p-d-flex p-jc-between">
              <div>
                <h3 class="p-m-0 p-d-inline-block">
                  {{
                    [comment.author_first_name, comment.author_last_name].join(
                      " "
                    )
                  }}
                </h3>
                <span v-if="comment.category === 'comment'" class="p-ml-2"
                  >left a comment</span
                >
                <span v-if="comment.category === 'contact'" class="p-ml-2"
                  >contacted the creator saying</span
                >
                <span class="comments-time p-ml-3">
                  {{ dayjs(comment.created_at).format("HH:mm") }}
                </span>
                <span
                  v-if="comment.category === 'comment'"
                  class="p-ml-3 p-tag p-tag-rounded p-tag-success comment-badge"
                  >Comment</span
                >
                <span
                  v-if="comment.category === 'contact'"
                  class="p-ml-3 p-tag p-tag-rounded p-tag-success contact-badge"
                  >Contact</span
                >
              </div>
              <div
                v-if="comment.author_id === user.id || isSuper"
                class="p-ml-4"
              >
                <i
                  class="pi pi-pencil p-mr-3 comments-edit-icon"
                  @click="editComment(comment.id)"
                ></i>
                <i
                  class="pi pi-trash comments-delete-icon"
                  @click="$emit('delete-comment', comment.id)"
                ></i>
              </div>
            </div>
            <div class="comments-content p-d-flex">
              <avatar
                :username="
                  [comment.author_first_name, comment.author_last_name].join(
                    ' '
                  )
                "
                :size="30"
                class="comments-avatar"
              />
              <div class="p-ml-1">
                <span class="p-mt-0" v-html="comment.HTML" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!readonly" class="p-mt-6 p-px-4">
      <div ref="commentInput" class="comments-input p-d-flex">
        <MentionTextBox
          class="p-ai-center"
          style="width: 100%"
          @comment-input-change="commentInputChange"
          ref="mention-text-box"
        />
        <span
          v-if="editing"
          class="p-mr-2"
          @mouseover="cancelEditingVisible = true"
          @mouseleave="cancelEditingVisible = false"
        >
          <span
            v-if="cancelEditingVisible"
            class="p-tag p-tag-warning"
            style="cursor: pointer"
            @click="cancelEditing"
          >
            Cancel
          </span>
          <span v-else class="p-tag p-tag-danger"> Editing </span>
        </span>
        <Dropdown
          v-else
          class="p-mr-2 comments-category-select"
          v-model="selectedCategory"
          :options="categoryOptions"
        />
        <Button
          icon="pi pi-arrow-right"
          class="comment-input-button"
          @click="submitComment"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { readIsManager, readIsSuper, readMe } from "@/store/auth/getters";

import MentionTextBox from "@/components/MentionTextBox.vue";

@Component({
  components: {
    MentionTextBox,
  },
})
export default class CommentsLog extends Vue {
  public selectedCategory = "Comment";
  public categoryOptions = ["Comment", "Contact"];
  public commentInput = { HTML: "", JSON: "" };
  public editedFullComment: any = null;
  public editing = false;
  public cancelEditingVisible = false;
  @Prop(Array) public comments: any[] | undefined;

  get user() {
    return readMe(this.$store);
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get readonly() {
    return !readIsManager(this.$store);
  }

  public commentInputChange(payload) {
    this.commentInput = payload;
  }

  public submitComment() {
    if (this.editing) {
      const payload = {
        HTML: this.commentInput.HTML,
        JSON: this.commentInput.JSON,
        author_id: this.user.id,
      };
      this.$emit("submit-update-comment", {
        id: this.editedFullComment.id,
        data: payload,
      });
    } else {
      const payload = {
        HTML: this.commentInput.HTML,
        JSON: this.commentInput.JSON,
        category: this.selectedCategory.toString().toLowerCase(),
        author_id: this.user.id,
      };
      this.$emit("submit-new-comment", payload);
    }
  }

  public async editComment(commentId: number) {
    const commentInputEl = this.$refs.commentInput as HTMLElement;
    if (commentInputEl) {
      commentInputEl.scrollIntoView({
        behavior: "smooth",
      });
    }

    let comment;
    const filtered = this.comments.filter((item) => item.id === commentId);
    if (filtered.length > 0) {
      comment = filtered[0];
    }
    this.editing = true;
    this.$refs["mention-text-box"]["editor"]["setContent"](comment.HTML);
    this.editedFullComment = comment;
    this.commentInput = comment;
  }

  public cancelEditing() {
    this.$refs["mention-text-box"]["editor"]["clearContent"]();
    this.editing = false;
    this.cancelEditingVisible = false;
  }
}
</script>

<style scoped>
.comments-container {
  max-height: 750px;
  overflow: auto;
}

.comments {
  border-left: 1px solid #707070;
}

.comments-item {
  padding-top: 2.8rem;
}

.comments-date,
.comments-time {
  color: #c0c4cc;
}

.comments-date {
  flex-shrink: 0;
}

.comments-date:before {
  content: "";
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background: #707070;
  border-radius: 50%;
  margin-left: -0.5rem;
  margin-right: 1rem;
}

.comments-content {
  border-radius: 4px;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 1.2rem 2.2rem;
}

.comments-avatar {
  flex-shrink: 0;
  margin-right: 0.75rem;
}

.comments-edit-icon {
  color: #409eff;
  cursor: pointer;
}

.comments-delete-icon {
  color: #ef373e;
  cursor: pointer;
}

.comments-input {
  display: flex;
  padding: 10px 20px;
  border: 1px solid #707070;
  border-radius: 25px;
  align-items: center;
}

.comment-badge {
  background: #f0faeb !important;
  color: #707070 !important;
  font-weight: normal;
}

.contact-badge {
  background: #fff3c2 !important;
  color: #707070 !important;
  font-weight: normal;
}

.comments a {
  color: #409eff;
  text-decoration: none;
}

.comments-category-select.p-dropdown {
  background: #ebeef5;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 4px;
}

.comment-input-button {
  padding: 5px 0 !important;
}
</style>
